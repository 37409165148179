<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Ambientes
      </div>
      <div class="text-500 mb-5">Ambientes</div>
      <div class="card">

        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Chips v-model="filter" placeholder="Pesquisar" @add="load" @remove="load" />
          </div>
          <div class="mt-2 ml-auto md:mt-0">
            <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2"
              @click="$router.push({ name: 'ambienteNew' })" />
          </div>
        </div>

        <Toast />
        <AppLoadingWrapper v-if="loading" />
        <DataTable v-if="!loading" dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack"
          breakpoint="640px">
          <template #empty> Nenhum registro encontrado. </template>
          <template #loading> Carregando registros. Aguarde ... </template>

          <Column :sortable="true" field="id" header="Código"></Column>

          <Column :sortable="true" field="nome" header="Nome"></Column>
          <Column bodyClass="text-right" headerStyle="width: 10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary"
                @click="toggleMenu($event, slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        <Menu ref="menu" :model="actionItems" :popup="true" />

        <Paginator v-show="!loading" :rows="perPage" :totalRecords="total" :v-model:first="page"
          template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>

        <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
            <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger"
              @click="deleteRecord" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import AreaMedicaService from "../../services/AreaMedicaService";
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
  data() {
    return {
      records: [],
      actionItems: [
        {
          label: 'Alterar',
          icon: 'pi pi-pencil',
          command: () => this.editRecord(this.actionRecord.id)
        },
        {
          label: 'Excluir',
          icon: 'pi pi-trash',
          command: () => this.confirmDeleteRecord(this.actionRecord)
        }
      ],
      page: 1,
      total: 0,
      totalPage: 0,
      perPage: 10,
      loading: false,
      recordDialog: false,
      deleteRecordDialog: false,
      filter: [],
    };
  },
  mounted() {
    this.$service = new AreaMedicaService("/ambientes");
    this.load();
  },
  watch: {
    page() {
      this.load();
    },
    tenant() {
      this.load();
    },
    currentCustomer() {
      this.tenantCustomers = getCurrentCustomers();
      this.load();
    },
  },
  computed: {
    tenant() {
      return getCurrentCompany();
    },
    currentCustomer() {
      return getCurrentCustomers();
    }
  },
  methods: {
    toggleMenu(event, data) {
      this.actionRecord = data
      this.$refs.menu.toggle(event);
    },
    confirmDeleteRecord(record) {
      this.record = record;
      this.deleteRecordDialog = true;
    },
    async deleteRecord() {
      if (this.record) {
        try {
          await this.$service.remove(this.record.id)
          this.record = {};
          this.load()
          this.deleteRecordDialog = false;
          this.$toast.add({ severity: 'success', summary: 'Empresa excluída com sucesso!', life: 3000 });
        } catch (err) {
          console.error(err)
          this.$toast.add({ severity: 'error', summary: 'Erro ao excluir a empresa!', life: 3000 });
        }
      }
    },
    editRecord(id) {
      this.$router.push("/ambiente/" + id);
    },

    async load() {
      this.loading = true;
      const { data } = await this.$service.findAll({
        limit: this.perPage,
        page: this.page,
        filter: this.filter,
      });
      this.records = data.items;
      this.total = data.total;
      const calculoPaginacao = data.total / this.perPage;
      this.totalPage =
        calculoPaginacao === Math.floor(calculoPaginacao)
          ? calculoPaginacao
          : Math.floor(calculoPaginacao) + 1;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
